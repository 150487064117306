import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";

export default (props) => (
  <AnimationRevealPage>
    <Hero tour={props.tourData} />
    <Features tour={props.tourData} />
  </AnimationRevealPage>
);
